import { Tooltip } from "antd";
import React from "react";
import '../../src/style.css'

const CustomTooltip = ({ title, children, ...tooltipProps }) => {
  return (
    <Tooltip
      title={title}
      placement="topLeft"
      overlayStyle={{
        whiteSpace: "normal",
        maxWidth: "400px",
        zIndex: 1050,
      }}
      overlayInnerStyle={{
        backgroundColor: "#ffffff",
        color: "#000000",
        fontWeight: 400,
        border: "1px solid #d9d9d9",
        borderRadius: "4px",
      }}
      getPopupContainer={(trigger) => trigger.parentNode}
      {...tooltipProps}
    >
      <span className="span3">{children}</span>
    </Tooltip>
  );
};

export default CustomTooltip;