import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Typography, Table, Button, Steps, Tag } from 'antd';
import { LeftSquareOutlined, UploadOutlined } from '@ant-design/icons';

const { Title } = Typography;
const { Step } = Steps;

function ContractorPFRecord() {
  const location = useLocation();
  const navigate = useNavigate();
  const { record } = location.state || {};

  // Data for the table
  const [data, setData] = useState([]);

  // Fetch or update data (mock fetch here, replace with actual API logic)
  useEffect(() => {
    const fetchedData = [
      {
        key: '1',
        workmenName: 'Rahul',
        workmenCode: 'HCW401103456',
        uanNumber: '10118343462',
        empBasicPay: '260000',
        basicWageDiff: 0,
        eePfWageReg: 31200,
        eePfEcrChallan: 31200,
        epsWageReg: 1250,
        complianceStatus: 'Matched', // Status for the condition
      },
      {
        key: '2',
        workmenName: 'Arun',
        workmenCode: 'HCW401103457',
        uanNumber: '10118344645',
        empBasicPay: '4026',
        basicWageDiff: 8,
        eePfWageReg: 1219,
        eePfEcrChallan: 1234,
        epsWageReg: 1377,
        complianceStatus: 'Mismatch', // Status for the condition
      },
    ];
    setData(fetchedData);
  }, []);

  // Define columns with additional conditional formatting
  const columns = [
    {
      title: 'Workmen Name',
      dataIndex: 'workmenName',
      key: 'workmenName',
    },
    {
      title: 'Workmen Code',
      dataIndex: 'workmenCode',
      key: 'workmenCode',
    },
    {
      title: 'UAN Number',
      dataIndex: 'uanNumber',
      key: 'uanNumber',
    },
    {
      title: 'Emp Basic Pay/Per Wage Reg',
      dataIndex: 'empBasicPay',
      key: 'empBasicPay',
    },
    {
      title: 'Basic Wage Diff',
      dataIndex: 'basicWageDiff',
      key: 'basicWageDiff',
    },
    {
      title: 'EE PF per Wage Reg',
      dataIndex: 'eePfWageReg',
      key: 'eePfWageReg',
    },
    {
      title: 'EE PF per ECR Challan',
      dataIndex: 'eePfEcrChallan',
      key: 'eePfEcrChallan',
    },
    {
      title: 'EPS per Wage Reg',
      dataIndex: 'epsWageReg',
      key: 'epsWageReg',
    },
    {
      title: 'Compliance Status',
      dataIndex: 'complianceStatus',
      key: 'complianceStatus',
      render: (status) => (
        <Tag color={status === 'Matched' ? 'green' : 'red'}>{status}</Tag>
      ),
    },
  ];

  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      {/* Back Button */}
      <Button
        type='link'
        onClick={() => navigate(-1)}
        style={{ marginBottom: '20px' }}>
        <LeftSquareOutlined /> Back
      </Button>

      {/* Stepper */}
      <Steps current={1} style={{ marginBottom: '20px' }}>
        <Step title='Dashboard' description='PF Compliance overview' />
        <Step title='Compliance Details' description='Details of records' />
        <Step title='Upload' description='Upload ECR file' />
      </Steps>

      {/* Title and Upload Button */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Title level={4} style={{ margin: 0 }}>
          March, 2024 Compliance
        </Title>
        <Button type='primary'>
          <UploadOutlined /> Upload
        </Button>
      </div>

      {/* Table */}
      <Table
        columns={columns}
        dataSource={data}
        pagination={{ pageSize: 5 }}
        scroll={{ x: 1500 }}
        bordered
        style={{
          marginTop: '20px',
          border: '1px solid #d9d9d9',
          borderRadius: '8px',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        }}
      />
    </div>
  );
}

export default ContractorPFRecord;
