import React, { useState } from 'react';
import { Steps, notification } from 'antd';
import Personaldetails from '../components/EmployeeOnboard/Personneldetails';
import Jobdetails from '../components/EmployeeOnboard/Jobdetails';
import Wagedetails from '../components/EmployeeOnboard/Wagedetails';
import Uploaddocument from '../components/EmployeeOnboard/Uploaddocument';
import SummarygenerationUpdate from '../components/EmployeeOnboard/SummarygenerationUpdate';
import {
  getEmployeeList,
  searchEmployee,
  getWorkmenLimit,
  getExcelData,
} from '../services/auth';
import useAuth from '../hooks/useAuth';
import dayjs from 'dayjs';

import axios from '../services/axiosInstance';
import { useParams } from 'react-router-dom';

const ORG_INFO_DEFAULT = {
  firstName: '',
  lastName: '',
  mobile: '',
  aadharNo: '',
  district: '',
  fatherName: '',
  martialStatus: '',
  emergencyContactName: '',
  emergencyContactNo: '',
  uan: '',
  status: '',
};

// const ORG_JOB_DEFAULT = {
//   firstName:"",
//   lastName:"",
//   mobile:"",
//   aadharNo:"",
//   district:"",

// };

const EmployeeEdit = () => {
  const { user, userConfig, logout } = useAuth();
  const orgId = userConfig?.orgId;
  const [currentPageNo, setCurrentPageNo] = React.useState(0);
  // const [personalDetails, setpersonalDetails] = React.useState(ORG_INFO_DEFAULT);
  const [personalDetails, setpersonalDetails] = React.useState();
  const [masterData, setMasterData] = React.useState([]);

  const [jobDetails, setjobDetails] = React.useState();
  const [wageDetails, setwageDetails] = React.useState();
  const [uploadDocument, setuploadDocument] = React.useState();
  //  const [locationId,setlocationId]=React.useState("TOPGUN001_DAL")
  const [Summary, setSummary] = React.useState();
  const [imageSrc, setImageSrc] = React.useState(null);
  const [updatedFields, setUpdatedFields] = useState({});
  const [newData, setNewData] = useState({});

  const data = {
    // personalDetails:personalDetails,
    // jobDetails: jobDetails,
    // wageDetails:wageDetails,
    // uploadDocument:uploadDocument,
    // locationId:locationId
  };

  //Api Call By Id
  const { id } = useParams();

  React.useEffect(() => {
    OrganizationInfo();
    getExcel();
  }, []);

  const OrganizationInfo = async () => {
    const response = await axios.get(`pass/details?id=${id}`);

    const Normaldata = response.data.emp.personalDetails;
    const jdata = response.data.emp.jobDetails;
    const wdata = response.data.emp.wageDetails;
    const ddata = response.data.emp.uploadDocument;
    const status = response.data.emp.status;
    const orgId = response.data.emp.orgId;
    const approvedBy = response.data.emp.approvedBy;
    const approvalStage = response.data.emp.approvalStage;
    const Edited = response.data.emp.Edited;
    const approvalsRequired = response.data.emp.approvalsRequired;
    const exceptions = response.data.emp.exceptions;
    // const safetyCerticationEndDate = response.data.emp.safetyCerticationEndDate;
    // const medicalValidityDate = response.data.emp.pmeValidityDate;

    let lastEpnHistory = [];
    if (Normaldata?.epnRenewHistory?.length > 0)
      lastEpnHistory =
        Normaldata.epnRenewHistory[Normaldata.epnRenewHistory.length - 1];

    setpersonalDetails({
      ...Normaldata,
      ...jdata,
      autoApproved: response?.data?.emp?.autoApproved,
      approvalsRequired,
      aadharNo: response?.data?.emp?.aadhar[0]?.maskedAadhaarNumber || '',
      rejectedBy: response?.data?.emp?.rejectedBy,

      Edited,
      status,
      orgId: orgId,
      id: id,
      approvedBy,
      approvers: response?.data?.emp?.approvers,
      exceptions,
      dob: Normaldata.dob ? dayjs(new Date(Normaldata.dob)) : '',
      Dob: Normaldata.Dob ? dayjs(new Date(Normaldata.Dob)) : '',
      epnRenew: Normaldata.epnRenew ? dayjs(new Date(Normaldata.epnRenew)) : '',

      statusFromDate: Normaldata?.statusFromDate
        ? dayjs(new Date(Normaldata?.statusFromDate))
        : '',
      statusToDate: Normaldata?.statusToDate
        ? dayjs(new Date(Normaldata?.statusToDate))
        : '',

      statusTerminateNoticeDate: Normaldata?.statusTerminateNoticeDate
        ? dayjs(new Date(Normaldata?.statusTerminateNoticeDate))
        : '',

      isFetchedFromAadhar: response?.data?.emp?.isFetchedFromAadhar,
      approvalStage,
      safetyTrainingFromDate: Normaldata?.safetyTrainingFromDate
        ? dayjs(new Date(Normaldata?.safetyTrainingFromDate))
        : '',
      safetyTrainingUptoDate: Normaldata?.safetyTrainingUptoDate
        ? dayjs(new Date(Normaldata?.safetyTrainingUptoDate))
        : '',
      // safetyTrainingUptoDate: Normaldata?.safetyTrainingUptoDate
      // ? dayjs(new Date(Normaldata?.safetyTrainingUptoDate))
      // : '',
      // epnRenewHistory: Normaldata?.epnRenewHistory||[],
      // Check if epnRenewHistory exists and is an array before mapping over it
      epnRenewHistory: Array.isArray(Normaldata.epnRenewHistory)
        ? Normaldata.epnRenewHistory.map((item) => ({
            ...item,
            epnValidityFromDate: item.epnValidityFromDate
              ? dayjs(item.epnValidityFromDate)
              : '',
            epnValidityToDate: item.epnValidityToDate
              ? dayjs(item.epnValidityToDate)
              : '',
            cardLostDate: item.cardLostDate ? dayjs(item.cardLostDate) : '',
            oldEpnCancelDate: item.oldEpnCancelDate
              ? dayjs(item.oldEpnCancelDate)
              : '',
            epnRenew: item.epnRenew ? dayjs(item.epnRenew) : '',
          }))
        : [],

      safetyCerticationEndDate_timeStamp:
        response.data.emp.safetyCerticationEndDate,
      pmeValidityDate_timeStamp: response.data.emp.pmeValidityDate,
      medicalNeeded: response?.data?.emp?.medicalNeeded,
      safetyNeeded: response?.data?.emp?.safetyNeeded,
    });
    //
    setjobDetails({
      ...jdata,

      doj: jdata?.doj ? dayjs(new Date(jdata?.doj)) : '',

      pmeDonedate: jdata?.pmeDonedate
        ? dayjs(new Date(jdata?.pmeDonedate))
        : '',
      pmeValidityDate: jdata?.pmeValidityDate
        ? dayjs(new Date(jdata?.pmeValidityDate))
        : '',

      dateOfTermination: jdata?.dateOfTermination
        ? dayjs(jdata?.dateOfTermination, 'YYYY-MM-DD')
        : '',
      // dlExpiry: jdata?.dlExpiry ? dayjs(jdata?.dlExpiry, 'YYYY-MM-DD') : '',
      dlExpiry: jdata?.dlExpiry ? dayjs(new Date(jdata?.dlExpiry)) : '',
      subSkillDate: jdata?.subSkillDate
        ? dayjs(jdata?.subSkillDate, 'YYYY-MM-DD')
        : '',

      approvalStage,
    });
    setwageDetails({
      ...wdata,
      //  effectiveDate:new Date(wdata.effectiveDate)
      effectiveDate: wdata.effectiveDate
        ? dayjs(wdata.effectiveDate, 'YYYY-MM-DD')
        : '',
      approvalStage,
    });
    setuploadDocument({
      ...ddata,
      annualMedicalCheckupDate: ddata.annualMedicalCheckupDate
        ? dayjs(new Date(ddata.annualMedicalCheckupDate))
        : '',

      approvalStage,
    });
    setSummary({});
  };

  const handleFieldUpdate = (fieldName, updatedValue) => {
    setUpdatedFields((prevFields) => ({
      ...prevFields,
      [fieldName]: updatedValue,
    }));
  };

  const handleNewDataUpdate = (newData) => {
    setNewData(newData);
  };

  function submit() {
    // Approved(data).then((res) => {
    //   notification.success({ message: res.data.message });
    // }).catch((err) => {
    //   notification.error({ message: err.response.data.message });
    // });;
  }

  const getExcel = async () => {
    getExcelData(orgId)
      .then((res) => {
        setMasterData(res.data.data.data);
      })
      .catch((error) => {});
  };

  const pageItems = [
    {
      key: 'personneldetails',
      title: 'Personal Details',
    },
    {
      key: 'jobdetails',
      title: 'Job Details',
    },
    {
      key: 'wagedetails',
      title: 'Wage Details',
    },
    {
      key: 'uploaddocument',
      title: 'Upload Document',
    },
    {
      key: 'summary',
      title: 'Summary Generation',
    },
  ];
  const next = (values) => {
    if (currentPageNo === 0) {
      setpersonalDetails((currData) => ({ ...currData, ...values }));
    }

    if (currentPageNo === 1) {
      setjobDetails((currData) => ({ ...currData, ...values }));
    }
    if (currentPageNo === 2) {
      setwageDetails(values);
    }
    if (currentPageNo === 3) {
      setuploadDocument(values);
    }
    if (currentPageNo === 4) {
      setSummary(values);

      // submit the data
      submit(values);
      return;
    }
    // setlocationId(values)

    setCurrentPageNo(currentPageNo + 1);
  };

  const prev = (values) => {
    if (currentPageNo === 1) {
      setjobDetails(values);
    }
    if (currentPageNo === 2) {
      setwageDetails(values);
    }
    if (currentPageNo === 3) {
      setuploadDocument(values);
    }
    if (currentPageNo === 4) {
      setSummary(values);
    }

    setCurrentPageNo(currentPageNo - 1);
  };

  return (
    <div>
      {/* <h2>Workmen Details</h2> <br /> */}
      <br />
      <Steps current={currentPageNo} /> <br />
      <br />
      {/* {currentPageNo === 0 && (
        // <Personaldetails
        //   initialValues={personalDetails}
        //   onNext={next}
        //   isViewOnly={true}
        //   setImageSrc={setImageSrc}
        //   imageSrc={imageSrc}
        //   masterData={masterData}
        //   onUpdateFields={handleFieldUpdate}
        //   onUpdateNewData={handleNewDataUpdate}
        //   epn={epnDocument}
        // />
        <Personaldetails
          initialValues={personalDetails}
          jobDetails={jobDetails}
          wageDetails={wageDetails}
          uploadDocument={uploadDocument}
          onNext={next}
          isViewOnly={true}
          setImageSrc={setImageSrc}
          imageSrc={imageSrc}
          masterData={masterData}
          onUpdateFields={handleFieldUpdate}
          onUpdateNewData={handleNewDataUpdate}
          epn={epnDocument}
        />
      )} */}
      {currentPageNo === 1 && (
        <Jobdetails
          initialValues={jobDetails}
          onNext={next}
          onPrev={prev}
          isViewOnly={true}
          masterData={masterData}
          onUpdateFields={handleFieldUpdate}
        />
      )}
      {currentPageNo === 2 && (
        <Wagedetails
          initialValues={wageDetails}
          onNext={next}
          onPrev={prev}
          isViewOnly={true}
          onUpdateFields={handleFieldUpdate}
        />
      )}
      {currentPageNo === 3 && (
        <Uploaddocument
          initialValues={uploadDocument}
          onNext={next}
          onPrev={prev}
          isViewOnly={true}
          personalDetails={personalDetails}
          jobDetails={jobDetails}
        />
      )}
      {currentPageNo === 0 &&
        (personalDetails?.approvalStage == 2 ||
          personalDetails?.approvalStage == 1 ||
          personalDetails?.medicalNeeded == 2 ||
          personalDetails?.safetyNeeded == 2) && (
          <SummarygenerationUpdate
            initialValues={personalDetails}
            onNext={next}
            onPrev={prev}
            jobDetails={jobDetails}
            wageDetails={wageDetails}
            imageSrc={imageSrc}
            uploadDocument={uploadDocument}
            updatedFields={updatedFields}
            newData={newData}
          />
        )}
    </div>
  );
};

export default EmployeeEdit;
