import axios from './axiosInstance';

export const getAuditCompactLogs = (
  startDate,
  endDate,
  role,
  status,
  action,
  description,
) => {
  return axios.get(
    `/audit/getCompactLogs?startDate=${startDate}&endDate=${endDate}&role=${role}&status=${status}&action=${action}&description=${description}`
  );
};
